<template>
  <div>

    <PopupInvoiceInfo :invoice-data="invoiceData" />

    <!-- ANCHOR Filters -->
    <ErrorNotiListFilters
      ref="errorNotiListFilters"
      :search-text.sync="searchText"
      @refetch-data-with-filters="refetchData"
      @refetch-data-without-filters="clearFilter"
    />

    <!-- Table Container Card -->
    <b-card
      style="max-height: 70vh"
      class=""
      no-body
    >
      <!-- SECTION Table Top -->
      <div>
        <b-row class="m-1">
          <!-- ANCHOR Table Top - Per Page -->
          <b-col
            cols="4"
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sizePerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>
            </v-select>
          </b-col>

          <b-col
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <!-- ANCHOR Table Top - Buttons(Add, Export) -->
            <div class="d-none d-lg-flex">
              <!-- Button Add -->
              <b-button
                style="min-width: 120px"
                variant="info"
                class="mr-1 px-lg-1 px-md-75"
                :to="{ name: 'apps-error-noti-add' }"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  {{ $t('errorNoti.create') }}
                </span>
              </b-button>

              <!-- Button Export -->
              <b-button
                style="min-width: 120px"
                variant="info"
                class="px-lg-1 px-md-75"
                @click="confirmExport()"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="DownloadIcon"
                    size="16"
                  />
                  {{ $t('export') }}
                </span>
              </b-button>
            </div>

            <!-- ANCHOR Table Top - Dropdown -->
            <div class="d-block d-lg-none">
              <b-dropdown
                variant="info"
                class="m-lg-2"
                boundary="window"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                  />
                  <span class="pl-50">{{ $t('moreDropdownTitle') }}</span>
                </template>
                <b-dropdown-item
                  :to="{ name: 'apps-error-noti-add' }"
                >
                  {{ $t('errorNoti.moreDropdown.add') }}
                </b-dropdown-item>

                <b-dropdown-item
                  @click="confirmExport()"
                >
                  {{ $t('errorNoti.moreDropdown.export') }}
                </b-dropdown-item>

              </b-dropdown>
            </div>
          </b-col>

          <!-- ANCHOR Table Top - Search -->
          <b-col
            cols="12"
            md="auto"
            class="mt-1 mt-md-0 px-50 flex-grow-1"
          >
            <div class="d-flex align-items-center">
              <b-input-group
                size="md"
                class="w-100 mr-md-1"
              >
                <template #prepend>
                  <div class="d-flex align-items-center border rounded-left px-1 bg-light-info">
                    <feather-icon
                      icon="SearchIcon"
                      size="20"
                    />
                  </div>
                </template>

                <b-form-input
                  v-model="searchText"
                  type="search"
                  :placeholder="$t('errorNoti.phSearch')"
                  :reduce="val => val.value"
                  debounce="500"
                  trim
                  @input="onDebounceSearch($event, onRefetchData)"
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>

        <b-row
          style="background-color: #92CBFD;"
          class="font-weight-bold text-white mx-0 py-25 py-md-75"
        >
          <b-col
            md="auto"
            class="d-flex align-items-center px-0 mx-1 mx-md-2"
          >
            <!-- ANCHOR Table Top - Selected Error Notifications -->
            <div class="d-inline-block">
              <template v-if="notSelect">
                {{ $tc('errorNoti.selectionCount', 0) }}
              </template>
              <template v-else-if="selectionCount === 1">
                {{ $tc('errorNoti.selectionCount', 1) }}
              </template>
              <template v-else>
                {{ $tc('errorNoti.selectionCount', selectionCount, { count: selectionCount }) }}
              </template>
            </div>
          </b-col>

          <b-col
            md="auto"
            class="d-flex flex-grow-1 justify-content-end align-items-center px-0 mx-1 mx-md-2"
          >
            <!-- ANCHOR Table Top - Action: Send To Sign -->
            <span
              v-if="!roleMama"
              :class="notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'"
            >
              {{ $t('errorNoti.btn.sendToSign') }}
            </span>

            <template v-if="roleMama">
              <!-- ANCHOR Table Top - Action: Sign -->
              <span
                class="ml-1 ml-md-2"
                :class="notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'"
              >
                {{ $t('invoice.btn.sign') }}
              </span>

              <!-- ANCHOR Table Top - Action: Refuse To Sign -->
              <span
                class="ml-50 ml-md-1"
                :class="notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'"
              >
                {{ $t('invoice.btn.refuseSign') }}
              </span>
            </template>
          </b-col>
        </b-row>
      </div>
      <!-- !SECTION -->

      <!-- SECTION Table content -->
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refErrorNotiListTable"
          style="max-height: 50vh"
          sticky-header
          class="position-relative"
          :items="fetchErrorNoti"
          responsive
          bordered
          :fields="tableColumns"
          primary-key="id"
          table-class="table-error-noti-list"
          show-empty
          :empty-text="$t('noRecordFund')"
          :sort-desc.sync="isSortDirDesc"
          :sort-by.sync="sortBy"
          no-border-collapse
        >

          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark"
            >
              <span
                class="text-nowrap"
              >
                {{ $t(`errorNoti.columns.${data.label}`) }}
              </span>
            </div>
          </template>

          <!-- ANCHOR Checkbox -->
          <template #cell(checkbox)="{ item }">
            <b-form-checkbox
              class="mr-0 ml-50 mt-50"
              name="check-box"
              inline
              :disabled="actions.canPreviewOnly(item.status)"
              :checked="isChecked(item.id)"
              @change="
                chooseItem(item.id);
              "
            />
          </template>

          <!-- ANCHOR invoices -->
          <template #cell(invoices)="{ item }">
            <div
              class="text-right text-primary cursor-pointer font-weight-bold"
              @click="openPopupInvoices(item.invoices)"
            >
              {{ item.invoices.length }}
            </div>
          </template>

          <!-- ANCHOR taAcceptDate -->
          <template #cell(taAcceptDate)="{ item }">
            <div class="mb-0 text-nowrap">
              {{ dateTime(item.taAcceptDate) }}
            </div>
          </template>

          <!-- ANCHOR Column status -->
          <template #cell(status)="{ item: { status } }">
            <b-badge
              variant="info"
              class="badge-glow"
            >
              {{ getLocaleLabelOfErrorNotiStatus(status) }}
            </b-badge>
          </template>
          <!-- ANCHOR Column createdAt -->
          <template #cell(createdAt)="{ item: { createdAt, createdBy } }">
            <div class="text-nowrap">
              {{ dateTime(createdAt) }}
            </div>
            <div class="text-nowrap">
              {{ getLocaleFullName(createdBy) }}
            </div>
          </template>

          <!-- ANCHOR Column updatedAt -->
          <template #cell(updatedAt)="{ item: { updatedAt, updatedBy } }">
            <div class="text-nowrap">
              {{ dateTime(updatedAt) }}
            </div>
            <div class="text-nowrap">
              {{ getLocaleFullName(updatedBy) }}
            </div>
          </template>

          <!-- ANCHOR Column Actions -->
          <template #cell(actions)="{ item }">
            <div class=" text-right text-nowrap">

              <!-- ANCHOR Column Actions - Preview Error Notification -->
              <b-link
                class="text-info"
              >
                <feather-icon
                  :id="`err-noti-row-${item.id}-preview-icon`"
                  icon="EyeIcon"
                  class="cursor-pointer"
                  :style="actions.canPreviewOnly(item.status)
                    ? 'margin-right: 25px;'
                    : 'margin-right: 7px;'
                  "
                  size="16"
                />
                <b-tooltip
                  :title="$t('errorNoti.preview')"
                  :target="`err-noti-row-${item.id}-preview-icon`"
                />
              </b-link>

              <!-- ANCHOR Column Actions - Dropdown -->
              <b-dropdown
                v-if="!actions.canPreviewOnly(item.status)"
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!-- TODO: add handler for actions -->

                <!-- ANCHOR Table row action dropdown: View Detail Error Notification -->
                <b-dropdown-item
                  v-if="actions.canViewDetail(item.status)"
                  :to="{ name: 'apps-error-noti-detail', params: { id: item.id } }"
                >
                  <feather-icon icon="BookOpenIcon" />
                  <span class="align-middle ml-50">{{ $t('Detail') }}</span>
                </b-dropdown-item>

                <!-- ANCHOR Table row action dropdown: Update Error Notification -->
                <b-dropdown-item
                  v-if="actions.canUpdateOrSendToSign(item.status)"
                  :to="{ name: 'apps-error-noti-update', params: { id: item.id } }"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('update') }}</span>
                </b-dropdown-item>

                <!-- ANCHOR Table row action dropdown: Send To Sign Error Notification -->
                <b-dropdown-item
                  v-if="actions.canUpdateOrSendToSign(item.status)"
                >
                  <feather-icon icon="ZapIcon" />
                  <span class="align-middle ml-50">{{ $t('errorNoti.btn.sendToSign') }}</span>
                </b-dropdown-item>

                <!-- ANCHOR Table row action dropdown: Sign Error Notification -->
                <b-dropdown-item
                  v-if="actions.canSignOrRefuseSign(item.status)"
                >
                  <feather-icon icon="ZapIcon" />
                  <span class="align-middle ml-50">{{ $t('invoice.btn.sign') }}</span>
                </b-dropdown-item>

                <!-- ANCHOR Table row action dropdown: Refuse To Sign Error Notification -->
                <b-dropdown-item
                  v-if="actions.canSignOrRefuseSign(item.status)"
                >
                  <feather-icon icon="ZapOffIcon" />
                  <span class="align-middle ml-50">{{ $t('invoice.btn.refuseSign') }}</span>
                </b-dropdown-item>

                <!-- ANCHOR Table row action dropdown: Delete Error Notification -->
                <b-dropdown-item
                  v-if="actions.canDelete(item.status)"
                  @click="confirmDelete(item.id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('errorNoti.btn.delete') }}</span>
                </b-dropdown-item>

              </b-dropdown>
            </div>
          </template>

        </b-table>

      </b-overlay>
      <!-- !SECTION -->

      <!-- ANCHOR: Table Footer -->
      <div class="mx-2 mb-1">
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BBadge,
  BLink,
  BTooltip,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
  BOverlay,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'

import store from '@/store'
import {
  ERROR_NOTI_APP_STORE_MODULE_NAME as ERROR_NOTI_STORE,
  ERROR_NOT_TABLE_COLUMNS as tableColumns,
} from '@/constants/errorNoti'
import {
  sizePerPageOptions,
} from '@/constants/selectOptions'

import {
  dateTime,
} from '@core/utils/filter'

import {
  // getLocaleLabelOfErrorNotiType,
  getLocaleLabelOfErrorNotiStatus,
  actions,
} from '../useErrorNoti'
import {
  getLocaleFullName,
  useShowToast,
} from '../../invoices/useInvoice'
import useErrorNotiHandle from './useErrorNotiHandle'
import errorNotiStoreModule from '../errorNotiStoreModule'
import ErrorNotiListFilters from './ErrorNotiListFilters.vue'
import PopupInvoiceInfo from './PopupInvoiceInfo.vue'

export default {
  components: {
    ErrorNotiListFilters,
    PopupInvoiceInfo,

    BCard,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BTooltip,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BOverlay,
    BTable,
    BPagination,

    vSelect,
  },

  /*
  props: {
  },
  */

  computed: {
    roleMama() {
      return this.$store.state.userStore.roleMama // true: role Mama, false: role Agency
    },
  },

  /*
  watch: {
  },
  */

  setup() {
    // Register module
    if (!store.hasModule(ERROR_NOTI_STORE)) {
      store.registerModule(ERROR_NOTI_STORE, errorNotiStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ERROR_NOTI_STORE)) {
        store.unregisterModule(ERROR_NOTI_STORE)
      }
    })

    const invoiceData = ref([])

    function confirmExport() {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.exportErrorNoti()
          }
        })
    }

    const onDebounceSearch = debounce((search, callBack) => {
      const trimSearch = search.trim()
      callBack(trimSearch)
    }, 500)

    function onRefetchData(trimSearch) {
      this.searchText = trimSearch
      this.$refs.errorNotiListFilters.onUpdateFilters()
    }

    function openPopupInvoices(data) {
      invoiceData.value = data
      this.$bvModal.show('popup-invoice-info')
    }

    function confirmDelete(id) {
      // show modal confirm delete employee
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmDelete') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteErrorNoti(id)
          }
        })
    }

    const {
      // Loading
      loading,
      // Refs
      refErrorNotiListTable,

      // Filters
      sizePerPage,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      fixFlightType,
      // Extra Filters
      searchText,

      totalItems,
      selectedIds,
      selectionCount,
      notSelect,

      isChecked,
      chooseItem,
      refetchData,
      clearFilter,

      // DISPATCH STORE
      fetchErrorNoti,
      exportErrorNoti,
      deleteErrorNoti,
    } = useErrorNotiHandle()

    return {
      // SECTION from import
      dateTime,
      getLocaleFullName,
      useShowToast,
      tableColumns,

      // select Options
      sizePerPageOptions,
      // !SECTION from import

      // SECTION useErrorNotiHandle()
      // Loading
      loading,
      // Refs
      refErrorNotiListTable,

      // Filters
      sizePerPage,
      currentPage,
      dataMeta,
      sortBy,
      isSortDirDesc,
      fixFlightType,
      // Extra Filters
      searchText,

      totalItems,
      selectedIds,
      selectionCount,
      notSelect,

      isChecked,
      chooseItem,
      refetchData,
      clearFilter,

      // DISPATCH STORE
      fetchErrorNoti,
      exportErrorNoti,
      // !SECTION useErrorNotiHandle()

      // SECTION setup()
      confirmExport,
      onDebounceSearch,
      onRefetchData,
      // !SECTION setup()

      // getLocaleLabelOfErrorNotiType,
      getLocaleLabelOfErrorNotiStatus,
      actions,
      confirmDelete,

      openPopupInvoices,
      invoiceData,
      deleteErrorNoti,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.table-error-noti-list {
  th {
    padding-left: 6px;
    padding-right: 6px;
  }

  td {
    font-size: 14px;
    padding: 6px !important;
  }
}
.hover-text-primary:hover {
  color: #3DA5DE;
}
.vs--disabled .vs__selected {
  color: inherit;
}
</style>
